import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import util from '../../utils/miniUtils';
import ImageComponent from '../../utils/ImageComponent';

import { TalentStore } from './TalentStore';
import 'react-circular-progressbar/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './profile.css';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const AWS_URL = '/s3services/download';

const ProfilePopup = ( { show, handleClose } ) => {
    const [open, setOpen] = useState( false );
    const [profilePicSrc, setProfilePicSrc] = useState( '/images/avatar.svg' );
    const talent = TalentStore.useState( state => state.talent );
    const isProfilePicAvailable = async () => {
        let url;
        if ( !util.noValues.includes( talent.profile_picture ) ) {
            url = "src/public/candidate/" + talent.id + "/" + talent.profile_picture;
        } else {
            url = "/images/avatar.svg";
        }
        try {
            const imageUrl = await util.fetchImageUrl( url );
            setProfilePicSrc( imageUrl || "/images/avatar.svg" );
        } catch ( error ) {
            console.error( 'Error setting profile picture:', error );
            setProfilePicSrc( "/images/avatar.svg" );
        }
    };

    useEffect( () => {
        isProfilePicAvailable();
    }, [talent.profile_picture] );
    const profileIcon = ( status ) => {
        return status ?
            <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2" />
            :
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger mr-2" />;
    };
    const sections = [
        { key: 'profilePicture', label: 'Profile Picture' },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'phone_mobile', label: 'Phone' },
        { key: 'salaryExpectation', label: 'Salary Expectation' },
        { key: 'skills', label: 'Skills' },
        { key: 'workHistory', label: 'Work History' },
        { key: 'videoProfile', label: 'Video Profile' }
    ];

    const completedSections = sections.filter( section => talent.percentage[section.key] );
    const incompleteSections = sections.filter( section => !talent.percentage[section.key] );

    const rawPercentage = talent.percentage.total;
    const percentage = parseInt( rawPercentage );
    const needDominantBaselineFix = true;
    if ( !show ) {
        return null;
    }

    return (
        <>
            {( percentage != 100 ) ?
                <div className="overlay-one">
                    <div className="modal-one">
                        <div className="left-section">
                            <div className="profile-container">
                                <div style={{ position: 'relative', width: 150, height: 150, margin: '0 auto' }}>
                                    <CircularProgressbar
                                        value={percentage}
                                        text={
                                            <tspan dy={needDominantBaselineFix ? 45 : 0} style={{ fontSize: '10px' }}>{percentage}%</tspan>
                                        }
                                        circleRatio={0.89}
                                        strokeWidth={8}
                                        className="custom-progress-bar-one"
                                        styles={buildStyles( {
                                            rotation: 1 / 2 + 1 / 16,
                                            strokeLinecap: "butt",
                                            trailColor: "#gray",
                                            trailWidth: 2,
                                            strokeWidth: "2",
                                            pathColor: "#0170ff",
                                            textColor: "black"
                                        } )}
                                    />
                                    <label style={{ position: 'absolute', top: '45%', left: '30%', transform: 'translate(-25%, -25%)', cursor: 'pointer' }}>
                                        <img
                                            width="100px"
                                            height="100px"
                                            alt="Profile"
                                            id="profilePicture"
                                            className="candidateprofile_picture"
                                            src={profilePicSrc}
                                            style={{ borderRadius: '50%', objectFit: 'cover' }}
                                        />
                                    </label>
                                </div>
                                <p className='text-center mt-5'>You're on your way {talent.name}!</p>
                            </div>
                        </div>
                        <div className="right-section">
                            <button onClick={handleClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                            <h4 className='mt-3'>Complete Your Profile</h4>
                            <div className="content">
                                {incompleteSections.map( section => (
                                    <div key={section.key}>
                                        {profileIcon( talent.percentage[section.key] )} {section.label}
                                        <hr />
                                    </div>
                                ) )}

                                <a
                                    href="#"
                                    className="d-block mt-2"
                                    onClick={() => setOpen( !open )}
                                    aria-controls="completed-list"
                                    aria-expanded={open}
                                >
                                    {open ? `Hide completed (${completedSections.length})` : `Show completed (${completedSections.length})`}
                                </a>
                                <small className="text-muted">Nicely done! These items are checked off the list.</small>
                                <hr />
                                {open && (
                                    <div id="completed-list">
                                        {completedSections.map( section => (
                                            <div key={section.key} className="completed">
                                                {profileIcon( talent.percentage[section.key] )} {section.label}
                                                <hr />
                                            </div>
                                        ) )}
                                    </div>
                                )}

                            </div>
                            <button onClick={handleClose} className="btn btn-secondary close-button-footer">Close</button>

                        </div>

                    </div>
                </div> :
                <div className="overlay-one">
                    <div className="modal-one">
                        <div className="left-section">
                            <div className="profile-container">
                                <div style={{ position: 'relative', width: 150, height: 150, margin: '0 auto' }}>
                                    <CircularProgressbar
                                        value={percentage}
                                        text={
                                            <tspan dy={needDominantBaselineFix ? 45 : 0} style={{ fontSize: '10px' }}>{percentage}%</tspan>
                                        }
                                        circleRatio={0.89}
                                        strokeWidth={8}
                                        className="custom-progress-bar-one"
                                        styles={buildStyles( {
                                            rotation: 1 / 2 + 1 / 16,
                                            strokeLinecap: "butt",
                                            trailColor: "#gray",
                                            trailWidth: 2,
                                            strokeWidth: "2",
                                            pathColor: "#0170ff",
                                            textColor: "black"
                                        } )}
                                    />
                                    <label style={{ position: 'absolute', top: '45%', left: '30%', transform: 'translate(-25%, -25%)', cursor: 'pointer' }}>
                                        <img
                                            width="100px"
                                            height="100px"
                                            alt="Profile"
                                            id="profilePicture"
                                            className="candidateprofile_picture"
                                            src={profilePicSrc}
                                            style={{ borderRadius: '50%', objectFit: 'cover' }}
                                        />
                                    </label>
                                </div>
                                <p className='text-center mt-5'>You're on your way {talent.name}!</p>
                            </div>
                        </div>
                        <div className="right-section">
                            <button onClick={handleClose} className="close-button"><FontAwesomeIcon icon={faTimes} /></button>
                            <h4 className='mt-3'>You Have Completed Your Profile</h4>
                            <div className="content">
                                {/* {incompleteSections.map(section => (
                                    <div key={section.key}>
                                        {profileIcon(talent.percentage[section.key])} {section.label}
                                        <hr />
                                    </div>
                                ))} */}

                                <a
                                    href="#"
                                    className="d-block mt-2"
                                    onClick={() => setOpen( !open )}
                                    aria-controls="completed-list"
                                    aria-expanded={open}
                                >
                                    {open ? `Hide completed (${completedSections.length})` : `Show completed (${completedSections.length})`}
                                </a>
                                <small className="text-muted">Nicely done! These items are checked off the list.</small>
                                <hr />
                                {open && (
                                    <div id="completed-list">
                                        {completedSections.map( section => (
                                            <div key={section.key} className="completed">
                                                {profileIcon( talent.percentage[section.key] )} {section.label}
                                                <hr />
                                            </div>
                                        ) )}
                                    </div>
                                )}

                            </div>
                            <button onClick={handleClose} className="btn btn-secondary close-button-footer">Close</button>

                        </div>

                    </div>
                </div>}
        </>
    );
};

export default ProfilePopup;
