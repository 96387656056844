import { Tooltip } from "@material-ui/core";
import util from "../../../utils/miniUtils";
import ImageComponent from '../../../utils/ImageComponent';
import React, { Fragment, useEffect, useState } from "react";
import { TalentStore } from "../TalentStore";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import "../../../../assets/css/global.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function ProfilePictureComponent() {

    const talent = TalentStore.useState( state => state.talent );
    // Internal state to handle up;load picture flow
    const [state, setState] = useState( {
        isUploading: false,
        tempImage: null,
        file: null
    } );

    useEffect( () => {
        isProfilePicAvailable();
    }, [talent.profile_picture] );

    useEffect( () => {
        setState( {
            isUploading: false,
            tempImage: null,
            file: null
        } );
        isProfilePicAvailable();
    }, [talent.forceReload] );

    const onChangeHandler = ( e ) => {
        let reader = new FileReader();
        reader.onload = function () {
            let output = document.getElementById( 'profilePicture' );
            output.src = reader.result;
        };
        reader.readAsDataURL( e.target.files[0] );
        setState( {
            ...state,
            isUploading: true,
            pictureSrc: reader.result,
            file: e.target.files[0]
        } );
    };

    const cancelUpload = () => {
        setState( { ...state, isUploading: false, pictureSrc: null, file: null } );
        isProfilePicAvailable();
    };

    const uploadPicture = async () => {
        try {
            const userId = talent.id;
            const infoObject = new FormData();
            infoObject.append( 'file', state.file );
            // eslint-disable-next-line no-restricted-globals
            infoObject.set( 'name', name );
            const response = await util.axios.post( `/candidates/upload_candidate_picture/${userId}`, infoObject );
            let { error, msg, candidate_profile, candidate } = response["data"];
            if ( error ) return util.handleError( msg );
            TalentStore.update( t => {
                t.talent.profile_picture = candidate_profile;
                // t.talent.percentage.profilePicture = candidate.percentage.profilePicture;
                // t.talent.percentage.total = candidate.percentage.total;
                t.forceReload = Date.now();
            } );
            setState( {
                isUploading: false,
                tempImage: null,
                file: null,
                pictureSrc: null
            } );
        } catch ( e ) {
            util.handleError( e );
        }
    };

    const [profilePicSrc, setProfilePicSrc] = useState( '/images/avatar.svg' );
    const isProfilePicAvailable = async () => {
        let url;
        if ( !util.noValues.includes( talent.profile_picture ) ) {
            url = "src/public/candidate/" + talent.id + "/" + talent.profile_picture;
        } else {
            url = "/images/avatar.svg";
        }
        try {
            console.log( url );
            const imageUrl = await util.fetchImageUrl( url );
            setProfilePicSrc( imageUrl || "/images/avatar.svg" );
        } catch ( error ) {
            console.error( 'Error setting profile picture:', error );
            setProfilePicSrc( "/images/avatar.svg" );
        }
    };
    const rawPercentage = talent.percentage.total; // Assuming rawPercentage is a string like '51.23%'
    const percentage = parseInt( rawPercentage );
    const needDominantBaselineFix = true;
    return (
        <Fragment>
            <div className="mt-0">
                <div className="text-center">
                    <div style={{ position: 'relative', display: "inline-block" }}>
                        {/* Circular Progress Bar */}
                        <CircularProgressbar
                            value={percentage}
                            text={
                                <tspan dy={needDominantBaselineFix ? 45 : 0} style={{ fontSize: '10px' }}>{percentage}%</tspan>
                            }
                            circleRatio={0.89}
                            strokeWidth={8}
                            className="custom-progress-bar"
                            styles={buildStyles( {
                                rotation: 1 / 2 + 1 / 16,
                                strokeLinecap: "butt",
                                trailColor: "#gray",
                                trailWidth: 2,
                                strokeWidth: "2",
                                pathColor: "#0170ff",
                                textColor: "black"
                            } )}
                        />
                        {/* Image */}
                        <Tooltip placement={"right-end"} title="Click to change the photo">
                            <label htmlFor="my_file" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <img
                                    width="150px"
                                    height="150px"
                                    alt="Profile"
                                    id="profilePicture"
                                    className="candidateprofile_picture"
                                    src={profilePicSrc}
                                />
                            </label>
                        </Tooltip>
                    </div>

                    {/* <CircularProgressbar
                        value={percentage}
                        text={`${percentage}%`}
                        circleRatio={0.75}
                        styles={buildStyles( {
                            rotation: 1 / 2 + 1 / 8,
                            strokeLinecap: "butt",
                            trailColor: "#eee"
                        } )}
                    />
                    <Tooltip placement={"right-end"} title="Click to change the photo">
                        <label htmlFor="my_file">
                            <img
                                width="50%"
                                alt="Profile"
                                id="profilePicture"
                                className="rounded candidateprofile_picture"
                                src=""
                            />
                        </label>
                    </Tooltip> */}

                </div>


            </div>
            {/* <div className="text-center">
                <b className="w-100 text-center">Profile {rawPercentage === "100%" ? "Completed" : `at ${rawPercentage}`}</b>
            </div> */}

            <div className="changeCandidateprofile_picture">
                <input
                    type="file"
                    id="my_file"
                    onChange={onChangeHandler}
                    style={{ display: "none" }}
                    accept=".jpg,.webp, jpeg"
                    accept=".jpg,.webp, jpeg"
                />
                {
                    state.isUploading ?
                        <div>
                            <div className="d-flex justify-content-center p-2">
                                <button onClick={cancelUpload} className="btn btn-danger btn-sm m-2">Cancel</button>
                                <button onClick={uploadPicture} className="btn btn-success btn-sm m-2">Upload</button>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </Fragment>
    );
}
