import React from "react";
import { useTranslation } from "react-i18next";

const Heading = ( { title, subtitle } ) => {
    const { t } = useTranslation();
    return (
        <>
            <div className='heading'>
                <h1>{t( 'connectTalent')}</h1>
                <p>{t( 'exploreCareers')}</p>
            </div>
        </>
    );
};

export default Heading;
