import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { createStore } from "redux";
import allReducers from "./redux/reducers/";
import './fonts/Roboto-Regular.ttf';
import { composeWithDevTools } from 'redux-devtools-extension';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        // const authenticationResult = event.payload ;
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});
const store = createStore(
    allReducers,
    composeWithDevTools()
);

ReactDOM.render(
    <StrictMode>

        <Provider store={store}>
       
            <Router>
                <MsalProvider instance={msalInstance}>
               
                    <App />

                </MsalProvider>
            </Router>
          
        </Provider>
    </StrictMode>,
    document.getElementById("root")
);
