import { PositionsStore } from "../../stores/PositionsStore";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
    faClock,
    faCommentDollar,
    faCopy,
    faDollarSign,
    faLevelUpAlt,
    faMapMarkerAlt,
    faMoneyBill,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import ApplyModal from "../../modals/apply_modal/ApplyModal";
import DrawBackPosition from "../../../jobs/components/jobLists/talent/DrawBackPosition";
import ModalCX from "../../modals/ModalCX";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { Facebook, LinkRounded, LinkedIn, Twitter } from "@material-ui/icons";

export default function PositionTitleSecondary( { job } ) {
    const position = PositionsStore.useState( state => state );
    const [isDrawingBack, setIsDrawingBack] = useState( false );
    const [open, setOpen] = useState( false );
  

    function paymentUtility( amount, country ) {
        if ( util.noValues.includes( amount ) ) return "~";
        return util.currencyFormat( amount.toString(), country );

    }
    const copyToClipboard = () => {
        const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;
        navigator.clipboard.writeText( url ).then( null );
        setOpen( true );
    };
    const handleClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setOpen( false );
    };
    return (
        <Fragment>

            <div className="col-md-12 ml-2">
                <div className="jobPublicStyle d-flex justify-content-between">
                    <div className="mb-2 ">
                        <div className="mb-2">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="  iconGray" style={{ marginRight: "8px" }} />
                            <b className="Job_DetailsCard ">Location:</b> {util.location( job.country, job.state, job.city )}
                        </div>
                        <div>
                            <FontAwesomeIcon icon={faLevelUpAlt} className="  iconGray" style={{ marginRight: "11px" }} />
                            <b className="Job_DetailsCard " >Level:</b> {job["level"]}
                        </div>
                    </div>


                </div>

                <div className="jobPublicStyle d-flex justify-content-between">
                    <div>
                        <div className="mb-2 ">
                            <FontAwesomeIcon icon={faClock} className="iconGray " />
                            <b className="Job_DetailsCard">Employment Type:</b> <u>{job.employment_type}</u>
                        </div>
                        {( job.employment_type !== "Fulltime" && job.employment_type !== "Fulltime " ) ? <div className="mb-2 ">
                            <FontAwesomeIcon icon={faClock} className="iconGray " />
                            <b className="Job_DetailsCard">Duration:</b> <u>{job.duration}</u>
                        </div> : ""}
                        <div className="mb-2 ml-1" >
                            <FontAwesomeIcon icon={faDollarSign} className=" iconGray " style={{ fontSize:"18px" }} />
                            <b className="Job_DetailsCard">Salary Range:</b> {util.buyFromBuyTo( job.buy_from, job.buy_to, job.country ) !== "~" ? `${util.buyFromBuyTo( job.buy_from, job.buy_to, job.country )} ${job.sell_rate_type}` : "Not disclosed" }
                        </div>
                    </div>  <div className="jobPublicStyle justify-content-end mr-5">
                        {job["applied"] ?
                            <Fragment>
                                {[10, 19, 11].indexOf( job["statusCandidate"] ) === 1 || job["talent_dropped"] ?
                                    <p>You withdrew the application</p>
                                    :
                                    <button className="btn btn-sm btn-danger float-right"
                                        onClick={() => setIsDrawingBack( true )}
                                    >
                                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                        Withdraw
                                    </button>
                                }
                            </Fragment>
                            :
                            <button
                                onClick={() => PositionsStore.update( s => {
                                    s.job=job;
                                    s.applyModal = true;
                                } )}
                                className="btn btn-sm align-self-center align-content-end" style={{ backgroundColor: "#DFE1E0",width:"120px",height:"30px" }}>
                                Apply
                            </button>
                        }
                    </div>
                </div>


            </div>


            <ModalCX
                title="Withdraw Position"
                show={isDrawingBack}
                handleClose={() => setIsDrawingBack( false )}
            >
                <DrawBackPosition
                    closeModal={() => setIsDrawingBack( false )}
                    jd={job}
                    refreshTable={() => window.location.reload()}
                    setIsDrawingBack={() => setIsDrawingBack( false )}
                />
            </ModalCX>

            {position.applyModal ? <ApplyModal /> : ""}
            {/*{position.createAccount ? <Register/>: null}*/}
            {/*{position.loginModal ? <Login/>: null}*/}
        </Fragment>
    );
}



