import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/er/translation.json';
import translationFR from './locales/en/translation.json';
import translationHI from './locales/hi/translation.json';
import translationPL from './locales/pl/translation.json';
import translationAR from './locales/ar/translation.json';
import translationSV from './locales/sv/translation.json';
import translationDE from './locales/es/translation.json';
import translationES from './locales/de/translation.json';
i18n.use( initReactI18next ).init( {
    resources: {
        en: { translation: translationEN },
        fr: { translation: translationFR },
        hi: { translation: translationHI },
        pl: { translation: translationPL },
        ar:{translation: translationAR},
        sv:{translation: translationSV},
        es:{translation: translationES},
        de:{translation: translationDE}
    },
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
} );

export default i18n;


// import i18next from "i18next";
// import HttpBackend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// const apiKey = "kBsWwJVJ36w-yK2-itAnmg";
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

// i18next
//     .use( HttpBackend )
//     .use( LanguageDetector )
//     .use( initReactI18next )
//     .init( {
//         fallbackLng: "en",

//         ns: ["default"],
//         defaultNS: "default",

//         supportedLngs: ["en","hi","mr","fr","zh","sv","ja"],
    
//         backend: {
//             loadPath: loadPath
//         },
//         react: {
//             useSuspense: true,
//         },
//     } );
// export default i18next;