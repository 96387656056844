import React, { useState, useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Login from "./components/auth/Login";
import { read_cookie, bake_cookie, delete_cookie } from "sfcookies";
import MainSection from "./components/MainSection";
import PasswordReset from "./components/auth/PasswordReset";
import EvaluateTalentSkills from "./components/skillEvaluation/EvaluateTalentSkills";
import util from "./components/utils/miniUtils";
import { Route, Switch } from "react-router-dom";
import Positions from "./components/positions/main/Positions2";
import EjTest from "./components/tests/EjTest";
import JobsAppliedTalent from "./components/positions/main/JobsAppliedTalent";
import JobSearchForm from "./components/LandingPage/home/JobSearchForm";
import JobsResult from "./components/positions/main/JobsResult";
import JobDetail from "./components/positions/main/job_detail/JobDetail2";
import Footer from "./components/LandingPage/common/footer/Footer";
import { PositionsStore } from "./components/positions/stores/PositionsStore";
import Header from "./components/LandingPage/common/header/Header";
import JobSearchFilter from "./components/positions/main/JobSearchFilter";
import { Chip } from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import useJobSearch from "./components/positions/main/useJobSearch";
import ProfileComponent from "./components/shared/profile_component/ProfileComponent";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../src/authConfig";
import axios from "axios";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import GreetingPopup from "./components/positions/modals/GreetingPopup";
import CodingAssessmentForm from "./components/tests/CodingAssessmentForm";
import i18next from "i18next";
import i18n from './components/i18n';
function App() {
    const [isLogged, setIsLogged] = useState( read_cookie( "tokenServer" ).length > 0 );
    const [initialAction, setInitialAction] = useState( null );
    const { instance } = useMsal();


    const location = useLocation();

    useEffect( () => {
        try {
            const queryParams = new URLSearchParams( window.location.search );

            const accessToken = queryParams.get( 'accessToken' );
            if ( accessToken ) {

                if ( accessToken ) {
                    bake_cookie( "tokenServer", accessToken );

                    const jobId = queryParams.get( 'job' );

                    let u = jwt_decode( accessToken );
                    let msg = 'Welcome ' + u.name;

                    // toast.success( msg );   
                    util.toast().success( msg );
                    if ( u.role === 2 && u.account_created === true ) {
                        PositionsStore.update( s => {
                            s.isLogged = true;
                            s.loginModal = false;
                            s.createAccount = false;
                            s.profileProcess = true;
                            s.user = jwt_decode( accessToken );
                        } );
                    } else {
                        if ( jobId !== null ) {
                            let u = jwt_decode( accessToken );
                            if ( u.role === 2 ) {
                                window.history.replaceState( "", "", `/careers?search=&job=${jobId}` );
                                window.location.reload();
                            } else {
                                window.history.replaceState( "", "", `/jobs` );
                            }

                        } else {
                            window.history.replaceState( "", "", "/" );
                            window.location.reload();
                        }
                        // Every time the talent log in, it will be redirected to the main page
                        setTimeout( () => {
                            window.location.reload();
                        }, 1500 );
                    }
                }
            }
        } catch ( e ) {
            PositionsStore.update( s => { s.loading = false; } );
            util.handleError( e );
        }

    }, [] );
    const position = PositionsStore.useState( s => s );

    const validateLogin = ( tokenServer ) => {
        if ( tokenServer.length > 0 ) {
            setIsLogged( true );
            window.location.reload();
        } else {
            setIsLogged( false );
        }
    };

    const logOut = () => {
        delete_cookie( "tokenServer" );

        // instance.logoutRedirect( {
        //     postLogoutRedirectUri: "/" // Optional: specify where you want the user to be redirected after logout
        // } ).catch( e => {
        //     console.error( e );
        // } );
        window.history.replaceState( "", "", "/" );
        window.location.reload();
    };
    // useEffect(() => {
    //     PositionsStore.update(s => { s.isModalVisible = true; });

    // }, [])
  
    useEffect( () => {
        const urlWindow = window.location;

        if ( urlWindow.pathname.split( "/" )[1] === "autologin" ) {
            const tok = urlWindow.pathname.split( "/" )[2];
            bake_cookie( "tokenServer", tok );
            window.history.replaceState( "", "", "/jobs" );
            window.location.reload();
        }

        if ( urlWindow.pathname.split( "/" )[1] === "reset_password" ) {
            const urlParams = new URLSearchParams( window.location.search );
            const token = urlParams.get( "token" );
            setInitialAction( () => <PasswordReset token={token} /> );
        }

        if ( urlWindow.pathname.split( "/" )[1] === "ej_test" ) {
            const urlParams = new URLSearchParams( window.location.search );
            const token = urlParams.get( "token" );
            setInitialAction( () => <EjTest token={token} /> );
        }

        if ( urlWindow.pathname.split( "/" )[1] === "coding_assessment" ) {
            // const urlParams = new URLSearchParams( window.location.search );
            // const token = urlParams.get( "token" );
            setInitialAction( () => <CodingAssessmentForm
            // token={token} 
            /> );
        }

        if (
            urlWindow.pathname.split( "/" )[1] === "job" &&
            urlWindow.pathname.split( "/" )[2] === "skills_evaluation"
        ) {
            const urlParams = new URLSearchParams( window.location.search );
            const token = urlParams.get( "token" );
            setInitialAction( () => <EvaluateTalentSkills token={token} /> );
        }
    }, [] );

    if ( initialAction ) {
        return initialAction;
    }

    if ( isLogged && util.user.role !== 2 ) {
        return <MainSection logOut={logOut} isLogged={isLogged} />;
    }


    { util.LOADING_SCREEN( position.loading ); }

    return (
        <>

            <Switch>
                {/*Applied jobs by talent */}
                <Route exact path={'/appliedJobs'}>
                    <JobsAppliedTalent isValid={true} userId={util.user.candidate_id} />
                </Route>
                {/*<Route exact path="/"><Redirect to={"/talent/profile"}/></Route>*/}
                <Route exact path={`/profile/${util.user.candidate_id}`}>
                    <MainSection logOut={logOut} isLogged={isLogged} />
                </Route>
                <Route exact path="/" component={Positions} />
                <Route exact path="/careers" component={JobSearch} />

                {/*<Route exact path="/careers/job/:id" component={JobDetail}/>*/}
                <Route exact path="/auth">
                    <Login checkIfLogged={validateLogin} />
                </Route>
                <Route exact path="/profile">
                    <Header />
                    <div className="ej_container pt-2">
                        <ProfileComponent talent_id={util.user.candidate_id} elevation={0} />
                    </div>
                    <Footer />
                </Route>
                {/* If nothing matches, will redirect to careers */}
            </Switch>
        </>
    );
}

export default App;

function JobSearch() {
    const position = PositionsStore.useState( state => state );
    const isSmallScreen = window.innerWidth < 768;
    const { getJobs } = useJobSearch();
    const location = useLocation();

    const hideSearch = location.search.includes( 'fs=true' );

    useEffect( () => {
        getJobs( location.search );
    }, [] );

    if ( position.loading ) util.LOADING_SCREEN( true );
    return (
        <>

            <Header />
            {position.isModalVisible ? <GreetingPopup /> : ""}

            <div className="position-relative" >
                {/* Job search form */}
                {!hideSearch ? <div className="form-second">
                    <JobSearchForm />
                    <div className="SelectedFilters">
                        <SelectedFilters />

                    </div>
                </div> : ""}
                <div className='d-flex jobSearch-container'>
                    {/* Search filter */}
                    {!hideSearch && position.showFilter ? <div className={`col-md-3 p-0 ${isSmallScreen ? 'mobile-filter-drawer' : ''}`}>
                        <JobSearchFilter />
                    </div> : ""}
                    {/* Jobs list */}
                    <div className={`d-flex p-0 ${position.showFilter ? 'col-md-9' : 'col-md-12'}`}>
                        <div className={isSmallScreen ? !position?.job?.id ? "col-md-12" : "d-none" : `col-md-${util.isFullScreenJob().sidebar} p-0`}>
                            <JobsResult />
                        </div>

                        {/* jobs description */}
                        <div className={isSmallScreen ? position?.job?.id ? "col-md-12" : "d-none" : `col-md-${util.isFullScreenJob().main} p-0`}>
                            <JobDetail />
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </>

    );
}

const SelectedFilters = () => {
    const filterState = PositionsStore.useState( state => state.filterState );

    const handleFilterRemove = ( el, filterValue ) => {
        PositionsStore.update( state => {
            state.filterState[el].map( item => {
                if ( item.value === filterValue.value ) {
                    item.checked = false;
                }
                return item;
            } );
        } );
    };

    const handleRemoveSkill = ( value ) => {
        PositionsStore.update( state => {
            state.filterState.skills = state.filterState.skills.filter( item => item !== value );
        } );
    };

    return <div className="ml-5" >
        {Object.keys( filterState ).map( ( el ) => filterState[el].length > 0 ?
            filterState[el].filter( item => item.checked ).map( ( value, i ) =>
                <Chip key={i} size="small" label={value.value} className="bg-light border rounded p-1 m-1 xs-fontSize" onDelete={() => handleFilterRemove( el, value )} />
            ) : "" )}
        {filterState["skills"].length > 0 ?
            filterState["skills"].map( ( value, i ) =>
                <Chip key={i} size="small" label={value} className="bg-light border rounded p-1 m-1 xs-fontSize" onDelete={() => handleRemoveSkill( value )} /> ) : ""}
    </div>;
};