/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { footer } from "../../data/Data";
import "./footer.css";
import ImageWithFallback from "../../../shared/ImageWithFallback";
import util from "../../../utils/miniUtils";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    const [appInfo, setAppInfo] = useState( { title: "", logo: "", small_logo: "" } );

    useEffect( () => {
        getAppInfo();
    }, [] );

    const getAppInfo = async () => {
        try {
            const request = await util.axios.get( '/app_resources/settings/app_info' );
            const { msg, error, data } = request.data;
            if ( error ) throw msg;
            setAppInfo( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };


    return (
        <>
            <footer>
                <div className='container'>
                    <div className='box'>
                        <div className='logo'>
                            {appInfo.logo ? <ImageWithFallback
                                src={`${util.baseUrl()}${util.AWSURL()}/global/images/${appInfo.logo}`}
                                alt={appInfo.title ? appInfo.title : "eJRekruit"}
                                fallbackSrc={'../images/ejamerica.webp'} /> :
                                <img src='../images/ejamerica.webp' alt='ejRekruit' />}

                        </div>
                    </div>

                    {footer.map( ( val, i ) => (
                        <div key={i} className='Cbox'>
                            <h3>{val.title}</h3>
                            <ul>
                                {val.text.map( ( items, index ) => (
                                    <li key={items.list + "-" + index}> {items.list} </li>
                                ) )}
                            </ul>
                        </div>
                    ) )}

                </div>

            </footer>
            <div className='legal'>
                <span>&copy; {currentYear} eJRekruit, All Rights Reserved. Powered By eJAmerica.</span>
            </div>
        </>
    );
};

export default Footer;
